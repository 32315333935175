import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Proimg from "../../assets/images/profissional-saude4.svg"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import colors from "../../styles/tokens/colors"

const Container = styled.div`
  padding: 0 6.875rem;

  section {
    width: 100%;
    max-width: 68.75rem;
    min-height: calc(100vh - 13.25rem);
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0 4rem;
    gap: 1.375rem;
  }
  .informativo {
    max-width: 33.688rem;
    .button-back {
      max-width: 21.75rem;
      height: 3rem;
    }
    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    div {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 2rem;
      max-width: 33.75rem;
    }
    div p {
      font-size: 0.875rem;
      font-weight: 400;
    }

    .suporte {
      font-size: 0.75rem;
      margin-bottom: 2rem;
    }
  }

  .img-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .image-credits {
    color: #515151;
    font-size: 0.75rem;
    align-self: flex-end;
    position: absolute;
    bottom: -0.75rem;
    right: 1rem;
    width: 6rem;
    .anchor-image {
      cursor: pointer;
      color: ${colors.gray.dark};
      text-decoration: underline;
    }
  }

  .pro-image {
    max-width: 29.196rem;
    max-height: 24.813rem;
  }
  @media screen and (max-width: 1020px) {
    padding: 0;
    gap: 0;
    justify-content: center;
    section {
      padding: 0 2.5rem;
    }
    .img-side {
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    .pro-image {
      max-width: 40vw;
      max-height: 36vw;
    }
  }
`
export default function VerificacaoConselhoDeClasse() {
  return (
    <Container>
      <Header />
      <section>
        <div className="informativo">
          <h2>Agora falta pouco!</h2>
          <div>
            <p>
              Para garantir a segurança de quem utiliza a Lacrei Saúde, fazemos
              a verificação do seu número de inscrição no respectivo Conselho
              Profissional.
            </p>
            <p>
              Em até 24h, você irá receber um link de confirmação através do seu
              e-mail cadastrado.
            </p>
            <p>
              Caso tenhamos alguma resposta negativa, também lhe avisaremos.
              Agradecemos sua inscrição na Lacrei Saúde.
            </p>
          </div>
          <p className="suporte">
            Se você não receber o link dentro de 24h, fale com a gente no{" "}
            <b>suporte@portallacrei.com.br</b>
          </p>
          <div className="button-back">
            <Link to="../profissionais-saude">
              <Button link="/" title="Voltar para a Tela Inicial" />
            </Link>
          </div>
        </div>
        <div className="img-side">
          <Proimg alt="Descrição da imagem" className="pro-image" />
          <span className="image-credits">
            Ilustrado por{" "}
            <a className="anchor-image" href="https://br.freepik.com/">
              Freepik
            </a>
          </span>
        </div>
      </section>
      <Footer />
    </Container>
  )
}
